import React from 'react'
import '../assets/css/Right.css'

const Right = () => {
  return (
    <div className='Right'>
      <p>目次配置</p>
      <p>ポーカー戦績</p>
    </div>
  )
}

export default Right