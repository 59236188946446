import React from 'react'
import '../assets/css/Main.css'
import Top from '../main_articles/Top.js'

const Main = () => {
  return (
    <div className='Main'>
        <Top/>
    </div>
  )
}

export default Main