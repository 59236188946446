import './assets/css/App.css';
import Left from './compornents/Left';
import Main from './compornents/Main';
import Right from './compornents/Right';


function App() {
  return (
    <div className="App">
      <Left/>
      <Main />
      <Right/>
    </div>
  );
}

export default App;
