import React from 'react'
import '../assets/css/Left.css'

export const Left = () => {
  return (
    <div className='Left'>何かコンテンツ入れよう</div>
  )
}

export default Left
